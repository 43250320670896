<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="navMenuItems" />
  </div>
</template>

<script>
import NavMenuMixin from '../../../NavMenuMixin.vue'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  name: 'HorizontalNavMenu',
  components: {
    HorizontalNavMenuItems,
  },
  mixins: [NavMenuMixin],
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
